@import-normalize;

html,
body {
  margin: 0;
  background-color: #666666;
}

html {
  height: 100%;
}
body {
  min-height: 100%;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.refinancingCreditsCheck {
  width: 100%;
}
